import { Trans } from "@lingui/macro"
import { Close } from "@mui/icons-material"
import { Box, MenuItem, Modal, Select, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import React, { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useMarketplace } from "../../../../contexts/MarketplaceContext"
import type { NFTTokenType } from "../../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../../../routes/routes"
import { getIPFSUri } from "../../../../utils/Helpers"
import { SoftButton } from "../../../elements/SoftButton"

interface IProps {
  NFTToken: NFTTokenType
}

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 520,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  outline: "none",
}

const ListNFTCard: React.FC<IProps> = ({ NFTToken }) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { listItem, marketplaceProject, marketplace } = useMarketplace()
  const { networks } = useWeb3Connection()

  const [isNFTListOpen, setIsNFTListOpen] = useState(false)
  const [NFTPrice, setNFTPrice] = useState("")
  const [NFTAmount, setNFTAmount] = useState("1")
  const [isListingNFT, setIsListingNFT] = useState(false)

  const { metadata, token_type, chain_id, supply } = NFTToken

  const onListNFT = useCallback(async () => {
    if (!NFTPrice || !marketplaceProject || !marketplace) return

    const amount = parseInt(NFTAmount)
    const price = parseFloat(NFTPrice)

    if (Number.isNaN(price)) {
      enqueueSnackbar("Please enter a valid price", {
        variant: "error",
        id: "toast-error-listing-price-invalid-value",
      })
      return
    }

    if (price <= 0) {
      enqueueSnackbar("Listing price must be greater than 0", {
        variant: "error",
        id: "toast-error-listing-price-lower-than-0",
      })
      return
    }

    if (Number.isNaN(amount)) {
      enqueueSnackbar("Quantity of sale must be integer numbers", {
        variant: "error",
        id: "toast-error-quantity-invalid-value",
      })
      return
    }

    if (amount <= 0) {
      enqueueSnackbar("Quantity of sale must be greater than 0", {
        variant: "error",
        id: "toast-error-quantity-lower-than-0",
      })
      return
    }

    if (amount > parseInt(supply)) {
      enqueueSnackbar("Quantity for sale must be less than or equal to tokens available", {
        variant: "error",
        id: "toast-error-quantity-bigger-than-available",
      })
      return
    }

    setIsListingNFT(true)
    listItem(NFTToken, NFTPrice, NFTAmount)
      .then(() => {
        setIsListingNFT(false)
        enqueueSnackbar("NFT listed on marketplace", {
          variant: "success",
          id: "toast-success-nft-listed",
        })

        navigate(ROUTE_LINKS.marketplaceView(marketplaceProject?.projectId, marketplace?.id))
      })
      .catch(() => {
        setIsListingNFT(false)
      })
  }, [
    NFTPrice,
    listItem,
    NFTToken,
    NFTAmount,
    enqueueSnackbar,
    marketplaceProject,
    marketplace,
    navigate,
    supply,
  ])

  const symbol = useMemo(
    () => networks.find((n) => n.chainId === chain_id)?.token || "ETH",
    [chain_id, networks]
  )

  if (!metadata) return null

  const { image, name } = metadata

  return (
    <>
      <Box
        width="100%"
        sx={{
          p: 1,
          borderRadius: "12px",
          border: "1px solid #262626",
          background: "#141414",
        }}
      >
        <Box height={180} display="flex" alignItems="center" mt={1.25} mb={1.25}>
          {image ? (
            <img
              style={{ width: "100%", maxHeight: 200, borderRadius: "8px", objectFit: "contain" }}
              src={getIPFSUri(image)}
              alt="collection"
              data-cy="label-nft-card-image"
            />
          ) : (
            <Box height="180px" width="100%" sx={{ background: "#595959", borderRadius: "8px" }} />
          )}
        </Box>
        <Box p={1} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography fontSize="18px" data-cy="label-nft-card-name">
              {name}
            </Typography>
            <Typography
              fontSize="14px"
              color="#8C8C8C"
              sx={{ pt: 0.5 }}
              data-cy="label-nft-card-token-type"
            >
              {token_type === "ERC721" ? "ERC-721" : "ERC-1155"}
            </Typography>
          </Box>
          <SoftButton
            onClick={() => setIsNFTListOpen(true)}
            sx={{
              color: "#DFF7C7",
              height: 40,
            }}
            data-cy="button-nft-card-list"
          >
            <Trans>List</Trans>
          </SoftButton>
        </Box>
      </Box>
      <Modal
        open={isNFTListOpen}
        onClose={() => {
          setIsNFTListOpen(false)
        }}
      >
        <Box sx={style}>
          <Close
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              fontSize: "24px",
              color: "#595959",
              cursor: "pointer",
            }}
            onClick={() => setIsNFTListOpen(false)}
            data-cy="button-list-item-close"
          />
          <Box width="100%" flex={1} pl={1} pr={1}>
            <Typography variant="h5" mb={3}>
              List item
            </Typography>
            <Box mt={2} gap={2} display="flex" flexDirection="column">
              <Box display="flex" gap={1} alignItems="center">
                <TextField
                  size="small"
                  label="Listing price"
                  placeholder="i.e 5"
                  value={NFTPrice}
                  type="number"
                  onChange={(e) => {
                    setNFTPrice(e.target.value)
                  }}
                  fullWidth
                  data-cy="input-list-item-price"
                />
                <Select
                  size="small"
                  sx={{ width: "160px" }}
                  value={symbol}
                  data-cy="dropdown-list-item-symbol"
                >
                  <MenuItem
                    key={symbol}
                    value={symbol}
                    data-cy={`dropdown-option-symbol-${symbol}`}
                  >
                    {symbol}
                  </MenuItem>
                </Select>
              </Box>
              {token_type === "ERC1155" ? (
                <Box display="flex" pl={1}>
                  <Box width="100%">
                    <Typography>Quantity for sale</Typography>
                    <Typography
                      variant="body2"
                      color="#595959"
                      data-cy="label-list-item-total-tokens"
                    >
                      Total tokens: {supply}
                    </Typography>
                  </Box>
                  <TextField
                    size="small"
                    placeholder="i.e 1"
                    value={NFTAmount}
                    type="number"
                    onChange={(e) => {
                      setNFTAmount(e.target.value)
                    }}
                    sx={{ width: "160px" }}
                    data-cy="input-list-item-quantity"
                  />
                </Box>
              ) : null}
              <Box display="flex" flexDirection="column" gap={1.5} mb={3}>
                <Box display="flex" justifyContent="space-between" alignItems="center" pl={1}>
                  <Box>
                    <Typography>
                      <Trans>Service fee</Trans>
                    </Typography>
                    <Typography variant="body2" color="#595959">
                      ChainSafe receives a 2% fee on all listings.
                    </Typography>
                  </Box>
                  <Typography data-cy="label-list-item-service-fee">2%</Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <SoftButton
                  disabled={!NFTPrice}
                  loading={isListingNFT}
                  onClick={onListNFT}
                  data-cy="button-list-item"
                >
                  <Trans>List item</Trans>
                </SoftButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ListNFTCard
