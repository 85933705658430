import { INFURA_PROJECT_ID } from "../config"

export type Network = {
  chainId: number
  token: string
  chain: string
  network: string
  label: string
  rpcUrl: string
  isLocalNetwork?: boolean
  isTestNetwork?: boolean
  isSeparateLabel?: boolean
  testLabel?: string
  lootboxFactoryAddress?: string
  collectionFactoryAddress?: string
  marketplaceFactoryAddress?: string
}

export const initialNetworks: Network[] = [
  {
    chainId: 31337,
    token: "ETH",
    chain: "Hardhat",
    network: "Testnet",
    label: "Hardhat Testnet",
    rpcUrl: `http://localhost:8545`,
    isLocalNetwork: true,
    isTestNetwork: true,
    lootboxFactoryAddress: "0x74D77ae3Da9f764d2E8e3cD14B5927b0a992DAb0",
  },
  {
    chainId: 1,
    token: "ETH",
    chain: "Ethereum",
    network: "Mainnet",
    label: "Ethereum Mainnet",
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    lootboxFactoryAddress: "0x7eDdF4bD10B5EfB4Dee533EFbe18937dF52e5e0D",
  },
  {
    chainId: 11155111,
    token: "ETH",
    chain: "Ethereum",
    network: "Sepolia",
    label: "Ethereum Sepolia",
    rpcUrl: `https://sepolia.infura.io/v3/${INFURA_PROJECT_ID}`,
    lootboxFactoryAddress: "0xfD33aD72d4E5B9BAFD07d91200107856e8b9E0D8",
    isTestNetwork: true,
    isSeparateLabel: true,
  },
  {
    chainId: 5,
    token: "ETH",
    chain: "Ethereum",
    network: "Goerli",
    label: "Ethereum Goerli",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_PROJECT_ID}`,
    isTestNetwork: true,
    isSeparateLabel: true,
  },
  // B3 network
  {
    chainId: 8333,
    token: "ETH",
    chain: "B3",
    network: "Mainnet",
    label: "B3 Mainnet",
    rpcUrl: `https://mainnet-rpc.b3.fun/http`,
    lootboxFactoryAddress: "0xfa97aCce8E4929e6d13a7c418BfbA0311e9D3Bfd",
    isTestNetwork: false,
  },
  {
    chainId: 1993,
    token: "ETH",
    chain: "B3",
    network: "Sepolia",
    label: "B3 Sepolia",
    rpcUrl: `https://sepolia.b3.fun/http`,
    lootboxFactoryAddress: "0x13f993c527b9A9EE77C32313b140404c70D2b699",
    isTestNetwork: true,
  },
  // Polygon network
  {
    chainId: 137,
    token: "Matic",
    chain: "Polygon",
    network: "Mainnet",
    label: "Polygon Mainnet",
    rpcUrl: `https://polygon-mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    lootboxFactoryAddress: "0x7eDdF4bD10B5EfB4Dee533EFbe18937dF52e5e0D",
  },
  {
    chainId: 80002,
    token: "Matic",
    chain: "Polygon",
    network: "Amoy Testnet",
    label: "Polygon Amoy",
    testLabel: "Amoy",
    rpcUrl: `https://polygon-amoy.infura.io/v3/${INFURA_PROJECT_ID}`,
    isTestNetwork: true,
    lootboxFactoryAddress: "0xfa97aCce8E4929e6d13a7c418BfbA0311e9D3Bfd",
  },
  // Manta network
  {
    chainId: 169,
    token: "ETH",
    chain: "Manta",
    network: "Mainnet",
    label: "Manta Mainnet",
    rpcUrl: `https://pacific-rpc.manta.network/http`,
  },
  {
    chainId: 3441006,
    token: "ETH",
    chain: "Manta",
    network: "Sepolia",
    label: "Manta Sepolia",
    rpcUrl: `https://pacific-rpc.sepolia-testnet.manta.network/http`,
    isTestNetwork: true,
  },
  // Binance network
  {
    chainId: 56,
    token: "BNB",
    chain: "Binance",
    network: "Mainnet",
    label: "Binance Mainnet",
    rpcUrl: `https://bsc-dataseed1.binance.org`,
    lootboxFactoryAddress: "0x7eDdF4bD10B5EfB4Dee533EFbe18937dF52e5e0D",
  },
  {
    chainId: 97,
    token: "BNB",
    chain: "Binance",
    network: "Testnet",
    label: "Binance Testnet",
    rpcUrl: `https://data-seed-prebsc-1-s3.binance.org:8545/`,
    isTestNetwork: true,
    lootboxFactoryAddress: "0xF899Bc1f149030c3912f1Cb473A7b2db0ed9cE5f",
  },
  // Avalanche network
  {
    chainId: 43114,
    token: "AVAX",
    chain: "Avalanche",
    network: "Mainnet",
    label: "Avalanche Mainnet",
    rpcUrl: `https://avalanche-mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    lootboxFactoryAddress: "0x7eDdF4bD10B5EfB4Dee533EFbe18937dF52e5e0D",
  },
  {
    chainId: 43113,
    token: "AVAX",
    chain: "Avalanche",
    network: "Testnet",
    label: "Avalanche Testnet",
    testLabel: "Fuji",
    rpcUrl: `https://avalanche-fuji.infura.io/v3/${INFURA_PROJECT_ID}`,
    isTestNetwork: true,
    lootboxFactoryAddress: "0x87d44C59B0322ec144fDdc4905C36aC9D9E2B6E4",
  },
  // Fantom network
  {
    chainId: 250,
    token: "FTM",
    chain: "Fantom",
    network: "Mainnet",
    label: "Fantom Mainnet",
    rpcUrl: `https://fantom-mainnet.public.blastapi.io`,
    lootboxFactoryAddress: "0x7eDdF4bD10B5EfB4Dee533EFbe18937dF52e5e0D",
  },
  {
    chainId: 4002,
    token: "FTM",
    chain: "Fantom",
    network: "Testnet",
    label: "Fantom Testnet",
    rpcUrl: `https://rpc.testnet.fantom.network/`,
    isTestNetwork: true,
    lootboxFactoryAddress: "0xfa97aCce8E4929e6d13a7c418BfbA0311e9D3Bfd",
  },
  // Arbitrum network
  {
    chainId: 42161,
    token: "FTM",
    chain: "Arbitrum",
    network: "Mainnet",
    label: "Arbitrum Mainnet",
    rpcUrl: `https://arbitrum-mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
    lootboxFactoryAddress: "0xbBBcB8148Ba1c9A6edf28bDE16A139614F741dF6",
  },
  {
    chainId: 421614,
    token: "FTM",
    chain: "Arbitrum",
    network: "Sepolia",
    label: "Arbitrum Sepolia",
    rpcUrl: `https://arbitrum-sepolia.infura.io/v3/${INFURA_PROJECT_ID}`,
    isTestNetwork: true,
    lootboxFactoryAddress: "0xfa97aCce8E4929e6d13a7c418BfbA0311e9D3Bfd",
  },
  // Cronos network
  {
    chainId: 25,
    token: "CRO",
    chain: "Cronos",
    network: "Mainnet",
    label: "Cronos Mainnet",
    rpcUrl: `https://evm.cronos.org`,
  },
  {
    chainId: 338,
    token: "tCRO",
    chain: "Cronos",
    network: "Testnet",
    label: "Cronos Testnet",
    rpcUrl: `https://evm-t3.cronos.org`,
    isTestNetwork: true,
  },
]

interface INetworkLabels {
  chain: string
  network: string
  testNetworkLabel?: string
  hasTestnet?: boolean
}

export function getNetwork(chainId: number, networks: Network[]): Network | undefined {
  return networks.find((n) => n.chainId === chainId)
}

export function getNetworkLabels(networks: Network[]): INetworkLabels[] {
  return networks
    .filter((n) => !n.isTestNetwork || n.isSeparateLabel)
    .map((n) => {
      return {
        chain: n.chain,
        network: n.network,
        testNetworkLabel: networks.find(
          (nT) => nT.chain === n.chain && nT.isTestNetwork && !nT.isSeparateLabel
        )?.testLabel,
        hasTestnet: !!networks.find(
          (nT) => nT.chain === n.chain && nT.isTestNetwork && !nT.isSeparateLabel
        ),
      }
    })
}
